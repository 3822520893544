import { FC, useState } from "react";
import css from "./index.module.css";
import crossIcon from "./svg/cross.svg";
import TextInput from "../UI/Inputs/TextInput";
import Button from "../UI/Buttons/Button";
import { HoverModalProps } from "./interfaces";
import TextArea from "../UI/TextArea";
import { api } from "../../api/api";
import { getCookie } from "../../helpers/cockieHelper";
import { useTranslation } from "react-i18next";

const HoverModal: FC<HoverModalProps> = ({ close, type, setModalType }) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState<string>("");
  const [wallet, setWallet] = useState<string>("");
  const [supportMessage, setSupportMessage] = useState<string>("");
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [panding, setPanding] = useState<boolean>(false);

  // Регулярное выражение для проверки email
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSentCode = async () => {
    if (panding) return;
    if (!emailPattern.test(email)) {
      alert(t("verifyEmail.notValidEmail"));
      return;
    }
    setPanding(true);
    try {
      const body = {
        email: email,
      };

      await api.post("/accounts/email/send_confirmation/", body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("key")?.toString()} `,
        },
      });

      alert(t("successfully"));
    } catch (error: any) {
      alert(t("verifyEmail.notValidEmailorUsed"));
    }
    setPanding(false);
  };
  const verifyEmail = async () => {
    if (!code.length && code.length < 8) {
      alert(t("verifyEmail.notValidCode"));
      return;
    }
    const body = {
      code: code,
    };

    try {
      const response = await api.post("accounts/email/confirm/", body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("key")?.toString()} `,
        },
      });
      close();
    } catch (error: any) {
      console.error("Error during login:", error);
      alert(t("verifyEmail.notValidData"));
    }
  };

  const handleSendToSupport = async (message: string) => {
    if (message.length < 1) {
      alert(t("modal.to_short"));
      return;
    }
    try {
      const response = await api.post(
        `/profile/me/support/`,
        { text: message },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getCookie("key")?.toString()} `,
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        if (!setModalType) return;
        setModalType("success");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const RenderBody = () => {
    switch (type) {
      case "withdrawal":
        return (
          <>
            <h2 className={css.title}>{t("modal.ORDER_WITHDRAWAL")}</h2>
            <p className={css.description}>{t("modal.withdraw_description")}</p>
            <div className={css.field}>
              <p className={css.label}>{t("modal.WITHDRAWAL_AMOUNT")}</p>
              <TextInput
                mask="9999999999"
                maskChar=""
                classes={{
                  input:
                    "bg-gradient-to-r from-[#232323] to-[#3A3A3A] text-[19px] max-h-[62px] text-white ",
                }}
                placehoder={t("modal.ENTER_THE_AMOUNT") as string}
                value={amount}
                onChange={setAmount}
              />
            </div>
            <div className={css.field}>
              <p className={css.label}>{t("modal.WALLET")}TRC-20</p>
              <TextInput
                classes={{
                  root: "mb-[24]",
                  input:
                    "bg-gradient-to-r from-[#232323] to-[#3A3A3A] text-[19px] max-h-[62px] text-white",
                }}
                placehoder={t("modal.ENTER_WALLET_NUMBER") as string}
                value={wallet}
                onChange={setWallet}
              />
            </div>
            <Button>{t("send")}</Button>
          </>
        );
      case "support":
        return (
          <>
            <h2 className={css.title}>{t("modal.Write_to_support")}</h2>
            <TextArea
              classes={{
                root: "mb-[24px]",
                input:
                  "bg-gradient-to-r from-[#232323] to-[#3A3A3A] text-[19px]  text-white p-[5px] max-h-[160px] ",
              }}
              value={supportMessage}
              onChange={setSupportMessage}
              placeholder={t("modal.Write_to_support") as string}
            />
            <Button onClick={() => handleSendToSupport(supportMessage)}>
              {t("send")}
            </Button>
          </>
        );
      case "verifyEmail":
        return (
          <>
            <h2 className={css.title}>{t("verifyEmail.verify")}</h2>
            <p className={css.description}>
              {t("verifyEmail.verifyDscription")}
            </p>
            <div className={css.field}>
              <p className={css.label}>Email</p>
              <TextInput
                placehoder="EMAIL"
                value={email}
                classes={{
                  root: "bg-gradient-to-r from-[#232323] to-[#3A3A3A]",
                  input: " bg-opacity-0 text-[19px] max-h-[62px] text-white ",
                }}
                onChange={setEmail}
                icon={
                  <span
                    onClick={() => handleSentCode()}
                    className="uppercase w-[150px]"
                  >
                    {t("verifyEmail.sendCode")}
                  </span>
                }
              />
            </div>
            <div className={css.field}>
              <p className={css.label}>{t("verifyEmail.codeFromPost")}</p>
              <TextInput
                placehoder={`${t("verifyEmail.emter")} ${t(
                  "verifyEmail.codeFromPost"
                ).toUpperCase()}`}
                classes={{
                  input:
                    "bg-gradient-to-r from-[#232323] to-[#3A3A3A] text-[19px] max-h-[62px] text-white ",
                }}
                value={code}
                onChange={setCode}
              />
            </div>
            <Button disabled={panding} onClick={verifyEmail}>
              {t("verifyEmail.verifyed")}
            </Button>
          </>
        );

      case "success":
        return (
          <>
            <h2 className={css.title}>{t("successfully")}</h2>
            <Button onClick={() => close()}>ОК</Button>
          </>
        );
    }
  };

  return (
    <div className={css.wrapper}>
      <div className={css.modal}>
        <img
          src={crossIcon}
          alt="выход"
          onClick={close}
          className={css.cancelIcon}
        />
        {RenderBody()}
      </div>
    </div>
  );
};

export default HoverModal;
