import i18n from "i18next";
import { initReactI18next, Translation } from "react-i18next";

import enTranslation from "./locales/en/index.json";
import ruTranslation from "./locales/ru/index.json";
import esTranslation from "./locales/es/index.json";
import geTranslation from "./locales/ge/index.json";

const safeLang = "ru";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ru: { translation: ruTranslation },
    es: { translation: esTranslation },
    ge: { translation: geTranslation },
  },
  lng: safeLang,
  fallbackLng: "en",
  interpolation: { escapeValue: false },
  react: {
    useSuspense: false,
  },
});

export default i18n;
