import { FC } from "react";
import css from "./TrideCard.module.css";
import cn from "classnames";
import { ReactComponent as CheckMark } from "./icons/chekMark.svg";
import { ReactComponent as EmptyMark } from "./icons/empty.svg";

import ShareButton from "../UI/Buttons/ShareButton";
import { formatMoney, formatNumber } from "../../utils/numberFormater";
import { useTranslation } from "react-i18next";

interface TradeCardProps {
  name: string;
  color: string;
  bothCount: number;
  maxCount: number;
  onClick: () => void;
  description?: string;
  size: string;
  procent: string;
  totalEarning: number;
  price: number;
  userRef?: string;
  deposite: number;
  level?: number;
}

const TradeCard: FC<TradeCardProps> = ({
  name,
  color,
  bothCount,
  onClick,
  description,
  size,
  maxCount,
  procent,
  totalEarning,
  price,
  userRef,
  deposite,
  level,
}) => {
  const { t } = useTranslation();
  const inviteText = `❇️ ${t("moneySstatistic.sharing_my_successes")} ❇️
    
${t("moneySstatistic.join_MEME_with_a_referral_link")}👾
  
  ${t("deposit")}: $${deposite ? deposite.toFixed(2) : 0}
  ${t("eraned")}: $${+totalEarning.toFixed(2)}
  ${t("profitability")}: ${procent}%
            `;

  return (
    <div onClick={() => onClick()} className="z-0">
      <div
        className={cn(css.wrapper, {
          [css.small]: size === "smal",
          [css.bigBG]: size === "big",
        })}
      >
        <div
          className={cn(css.blur, {
            [css.backgroundBlueBig]: color === "blue",
            [css.backgroundRedBig]: color === "red",
            [css.backgroundPurpleBig]: color === "purple",
            [css.backgroundGreenBig]: color === "green",
            [css.backgroundGoldBig]: color === "gold",
            [css.backgroundDarckGreendBig]: color === "darck-green",
            [css.backgroundLigthPinkBig]: color === "pink",
            [css.backgroundLigthOrangeBig]: color === "orange",
            [css.backgroundLigthGreenBig]: color === "ligth-green",
          })}
        ></div>

        <div className={css.header}>
          <h2 className={css.label}>{name.toUpperCase()}</h2>
          {userRef && (
            <ShareButton inviteText={inviteText} userRefLink={userRef} />
          )}
        </div>
        {size === "smal" && (
          <>
            <div className={css.statistic}>
              <div>
                <p className={css.statisticTitle}>
                  {t("profitability").toUpperCase()}
                </p>
                <p className={css.statisticAmount}>
                  <span className={css.wavyEquals}>≈</span>
                  {procent}%
                </p>
              </div>
              <div>
                <p className={css.statisticTitle}>
                  {t("eraned").toUpperCase()}
                </p>
                <p className={css.statisticAmount}>
                  ${formatMoney(totalEarning)}
                </p>
              </div>
              <div>
                <p className={css.statisticTitle}>{t("coast").toUpperCase()}</p>
                <p className={css.statisticAmount}>${formatMoney(price / 5)}</p>
              </div>
            </div>
            <div className={css.customLine}></div>
          </>
        )}

        {description && <p className={css.description}>{description}</p>}

        {size === "big" && (
          <div className={css.statisticWrap}>
            <div className={css.statisticBig}>
              <div>
                <p className={css.statisticTitle}>{t("tradeCard.deposit")}</p>
                <p className={css.statisticAmount}>${deposite}</p>
              </div>
              <div>
                <p className={css.statisticTitle}>{t("tradeCard.purchase")}</p>
                <p className={cn(css.statisticAmount, css.right)}>
                  ${formatMoney((price / maxCount) * bothCount)}
                </p>
              </div>
            </div>
            <div className={css.statisticBig}>
              <div>
                <p className={css.statisticTitle}>{t("tradeCard.profit")}</p>
                <p className={css.statisticAmount}>{procent}%</p>
              </div>
              <div>
                <p className={css.statisticTitle}>{t("tradeCard.earned")}</p>
                <p className={cn(css.statisticAmount, css.right)}>
                  ${formatMoney(totalEarning)}
                </p>
              </div>
            </div>
          </div>
        )}
        {size === "big" && <div className={cn(css.customLine, "mb-3")}></div>}
        <div className={css.footerWraper}>
          <span className={css.footer}>{t("tradeCard.purchase")}</span>
          <div
            className={cn(css.labels, {
              [css.markBlue]: color === "blue",
              [css.markRed]: color === "red",
              [css.markPurpule]: color === "purple",
              [css.markGreen]: color === "green",
              [css.markDackGreen]: color === "darck-green",
              [css.markLightGreen]: color === "ligth-green",
              [css.markOrange]: color === "orange",
              [css.markPink]: color === "pink",
              [css.markGold]: color === "gold",
            })}
          >
            {[...Array(maxCount - bothCount)].map((_, index) => (
              <CheckMark key={index} />
            ))}
            {[...Array(bothCount)].map((_, index) => (
              <EmptyMark key={index} />
            ))}
            {level && <p className="w-8">{`${level} ${t("lvl")}`}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeCard;
