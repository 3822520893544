import { FC } from "react";
import RefCard from "../UI/RefCard";
import { formatNumber } from "../../utils/numberFormater";
import { BonucesTableType, IRefCardsList } from "./constants";
import { useTranslation } from "react-i18next";

const RefCardsList: FC<IRefCardsList> = ({
  userData,
  setRefScreen,
  setDetailedState,
  refersData,
}) => {
  const { t } = useTranslation();

  const REFERAL_BONUCE_TABLE: BonucesTableType[] = [
    {
      value: "lvl_1",
      label: `${t("lvl")}.1`,
      isActive: true,
      licenseData: [
        { money: `50`, procent: `10%` },
        { money: `100`, procent: `10%` },
        { money: `200`, procent: `10%` },
        { money: `300`, procent: `10%` },
        { money: `500`, procent: `10%` },
        { money: `1000`, procent: `10%` },
        { money: `2000`, procent: `10%` },
        { money: `5000`, procent: `10%` },
        { money: `10000`, procent: `10%` },
      ],
    },
    {
      value: `lvl_2`,
      label: `${t("lvl")}.2`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `5%` },
        { money: `100`, procent: `5%` },
        { money: `200`, procent: `6%` },
        { money: `300`, procent: `6%` },
        { money: `500`, procent: `7%` },
        { money: `1000`, procent: `7%` },
        { money: `2000`, procent: `8%` },
        { money: `5000`, procent: `9%` },
        { money: `10000`, procent: `10%` },
      ],
    },
    {
      value: `lvl_3`,
      label: `${t("lvl")}.3`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `4%` },
        { money: `100`, procent: `4%` },
        { money: `200`, procent: `5%` },
        { money: `300`, procent: `5%` },
        { money: `500`, procent: `6%` },
        { money: `1000`, procent: `6%` },
        { money: `2000`, procent: `7%` },
        { money: `5000`, procent: `7%` },
        { money: `10000`, procent: `8%` },
      ],
    },
    {
      value: `lvl_4`,
      label: `${t("lvl")}.4`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `x` },
        { money: `100`, procent: `2%` },
        { money: `200`, procent: `3%` },
        { money: `300`, procent: `4%` },
        { money: `500`, procent: `5%` },
        { money: `1000`, procent: `5%` },
        { money: `2000`, procent: `6%` },
        { money: `5000`, procent: `7%` },
        { money: `10000`, procent: `7%` },
      ],
    },
    {
      value: `lvl_5`,
      label: `${t("lvl")}.5`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `x` },
        { money: `100`, procent: `x` },
        { money: `200`, procent: `x` },
        { money: `300`, procent: `1%` },
        { money: `500`, procent: `3%` },
        { money: `1000`, procent: `4%` },
        { money: `2000`, procent: `5%` },
        { money: `5000`, procent: `5%` },
        { money: `10000`, procent: `6%` },
      ],
    },
    {
      value: `lvl_6`,
      label: `${t("lvl")}.6`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `x` },
        { money: `100`, procent: `x` },
        { money: `200`, procent: `x` },
        { money: `300`, procent: `x` },
        { money: `500`, procent: `1%` },
        { money: `1000`, procent: `1%` },
        { money: `2000`, procent: `2%` },
        { money: `5000`, procent: `3%` },
        { money: `10000`, procent: `3%` },
      ],
    },
    {
      value: `lvl_7`,
      label: `${t("lvl")}.7`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `x` },
        { money: `100`, procent: `x` },
        { money: `200`, procent: `x` },
        { money: `300`, procent: `x` },
        { money: `500`, procent: `x` },
        { money: `1000`, procent: `1%` },
        { money: `2000`, procent: `2%` },
        { money: `5000`, procent: `2%` },
        { money: `10000`, procent: `2%` },
      ],
    },
    {
      value: `lvl_8`,
      label: `${t("lvl")}.8`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `x` },
        { money: `100`, procent: `x` },
        { money: `200`, procent: `x` },
        { money: `300`, procent: `x` },
        { money: `500`, procent: `x` },
        { money: `1000`, procent: `x` },
        { money: `2000`, procent: `0.5%` },
        { money: `5000`, procent: `0.5%` },
        { money: `10000`, procent: `1%` },
      ],
    },
    {
      value: `lvl_9`,
      label: `${t("lvl")}.9`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `x` },
        { money: `100`, procent: `x` },
        { money: `200`, procent: `x` },
        { money: `300`, procent: `x` },
        { money: `500`, procent: `x` },
        { money: `1000`, procent: `x` },
        { money: `2000`, procent: `x` },
        { money: `5000`, procent: `0.5%` },
        { money: `10000`, procent: `0.5%` },
      ],
    },
    {
      value: `lvl_10`,
      label: `${t("lvl")}.10`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `x` },
        { money: `100`, procent: `x` },
        { money: `200`, procent: `x` },
        { money: `300`, procent: `x` },
        { money: `500`, procent: `x` },
        { money: `1000`, procent: `x` },
        { money: `2000`, procent: `x` },
        { money: `5000`, procent: `x` },
        { money: `10000`, procent: `0.5%` },
      ],
    },
  ];

  const MATCHING_BONUCE_TABLE: BonucesTableType[] = [
    {
      value: `lvl_1`,
      label: `${t("lvl")}.1`,
      isActive: true,
      licenseData: [
        { money: `50`, procent: `5% 2ЛП` },
        { money: `100`, procent: `5%` },
        { money: `200`, procent: `5%` },
        { money: `300`, procent: `5%` },
        { money: `500`, procent: `5%` },
        { money: `1000`, procent: `5%` },
        { money: `2000`, procent: `5%` },
        { money: `5000`, procent: `5%` },
        { money: `10000`, procent: `5%` },
      ],
    },
    {
      value: `lvl_2`,
      label: `${t("lvl")}.2`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `5%  4ЛП` },
        { money: `100`, procent: `5%` },
        { money: `200`, procent: `5%` },
        { money: `300`, procent: `5%` },
        { money: `500`, procent: `5%` },
        { money: `1000`, procent: `5%` },
        { money: `2000`, procent: `5%` },
        { money: `5000`, procent: `5%` },
        { money: `10000`, procent: `5%` },
      ],
    },
    {
      value: `lvl_3`,
      label: `${t("lvl")}.3`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `5%  6ЛП` },
        { money: `100`, procent: `5%` },
        { money: `200`, procent: `5%` },
        { money: `300`, procent: `5%` },
        { money: `500`, procent: `5%` },
        { money: `1000`, procent: `5%` },
        { money: `2000`, procent: `5%` },
        { money: `5000`, procent: `5%` },
        { money: `10000`, procent: `5%` },
      ],
    },
    {
      value: `lvl_4`,
      label: `${t("lvl")}.4`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `x` },
        { money: `100`, procent: `5%  8ЛП` },
        { money: `200`, procent: `5%` },
        { money: `300`, procent: `5%` },
        { money: `500`, procent: `5%` },
        { money: `1000`, procent: `5%` },
        { money: `2000`, procent: `5%` },
        { money: `5000`, procent: `5%` },
        { money: `10000`, procent: `5%` },
      ],
    },
    {
      value: `lvl_5`,
      label: `${t("lvl")}.5`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `x` },
        { money: `100`, procent: `x` },
        { money: `200`, procent: `x` },
        { money: `300`, procent: `5% 10ЛП` },
        { money: `500`, procent: `5%` },
        { money: `1000`, procent: `5%` },
        { money: `2000`, procent: `5%` },
        { money: `5000`, procent: `5%` },
        { money: `10000`, procent: `5%` },
      ],
    },
    {
      value: `lvl_6`,
      label: `${t("lvl")}.6`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `x` },
        { money: `100`, procent: `x` },
        { money: `200`, procent: `x` },
        { money: `300`, procent: `x` },
        { money: `500`, procent: `5% 12ЛП` },
        { money: `1000`, procent: `5%` },
        { money: `2000`, procent: `5%` },
        { money: `5000`, procent: `5%` },
        { money: `10000`, procent: `5%` },
      ],
    },
    {
      value: `lvl_7`,
      label: `${t("lvl")}.7`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `x` },
        { money: `100`, procent: `x` },
        { money: `200`, procent: `x` },
        { money: `300`, procent: `x` },
        { money: `500`, procent: `x` },
        { money: `1000`, procent: `5% 14ЛП` },
        { money: `2000`, procent: `5%` },
        { money: `5000`, procent: `5%` },
        { money: `10000`, procent: `5%` },
      ],
    },
    {
      value: `lvl_8`,
      label: `${t("lvl")}.8`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `x` },
        { money: `100`, procent: `x` },
        { money: `200`, procent: `x` },
        { money: `300`, procent: `x` },
        { money: `500`, procent: `x` },
        { money: `1000`, procent: `x` },
        { money: `2000`, procent: `5% 16ЛП` },
        { money: `5000`, procent: `5%` },
        { money: `10000`, procent: `5%` },
      ],
    },
    {
      value: `lvl_9`,
      label: `${t("lvl")}.9`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `x` },
        { money: `100`, procent: `x` },
        { money: `200`, procent: `x` },
        { money: `300`, procent: `x` },
        { money: `500`, procent: `x` },
        { money: `1000`, procent: `x` },
        { money: `2000`, procent: `x` },
        { money: `5000`, procent: `5%  18ЛП` },
        { money: `10000`, procent: `5%` },
      ],
    },
    {
      value: `lvl_10`,
      label: `${t("lvl")}.10`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `x` },
        { money: `100`, procent: `x` },
        { money: `200`, procent: `x` },
        { money: `300`, procent: `x` },
        { money: `500`, procent: `x` },
        { money: `1000`, procent: `x` },
        { money: `2000`, procent: `x` },
        { money: `5000`, procent: `x` },
        { money: `10000`, procent: `5%  20ЛП` },
      ],
    },
  ];

  const QUICK_START_TABLE: BonucesTableType[] = [
    {
      value: `lvl_1`,
      label: `${t("lvl")}.1`,
      isActive: true,
      licenseData: [
        { money: `50`, procent: `20$` },
        { money: `100`, procent: `20$` },
        { money: `200`, procent: `20$` },
        { money: `300`, procent: `20$` },
        { money: `500`, procent: `20$` },
        { money: `1000`, procent: `20$` },
        { money: `2000`, procent: `20$` },
        { money: `5000`, procent: `20$` },
        { money: `10000`, procent: `20$` },
      ],
    },
    {
      value: `lvl_2`,
      label: `${t("lvl")}.2`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `30$` },
        { money: `100`, procent: `30$` },
        { money: `200`, procent: `30$` },
        { money: `300`, procent: `30$` },
        { money: `500`, procent: `40$` },
        { money: `1000`, procent: `40$` },
        { money: `2000`, procent: `40$` },
        { money: `5000`, procent: `40$` },
        { money: `10000`, procent: `40$` },
      ],
    },
    {
      value: `lvl_3`,
      label: `${t("lvl")}.3`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `40$` },
        { money: `100`, procent: `40$` },
        { money: `200`, procent: `40$` },
        { money: `300`, procent: `40$` },
        { money: `500`, procent: `60$` },
        { money: `1000`, procent: `60$` },
        { money: `2000`, procent: `80$` },
        { money: `5000`, procent: `80$` },
        { money: `10000`, procent: `80$` },
      ],
    },
    {
      value: `lvl_4`,
      label: `${t("lvl")}.4`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `60$` },
        { money: `100`, procent: `60$` },
        { money: `200`, procent: `60$` },
        { money: `300`, procent: `78$` },
        { money: `500`, procent: `90$` },
        { money: `1000`, procent: `96$` },
        { money: `2000`, procent: `120$` },
        { money: `5000`, procent: `120$` },
        { money: `10000`, procent: `120$` },
      ],
    },
    {
      value: `lvl_5`,
      label: `${t("lvl")}.5`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `100$` },
        { money: `100`, procent: `100$` },
        { money: `200`, procent: `100$` },
        { money: `300`, procent: `130$` },
        { money: `500`, procent: `150$` },
        { money: `1000`, procent: `170$` },
        { money: `2000`, procent: `200$` },
        { money: `5000`, procent: `200$` },
        { money: `10000`, procent: `200$` },
      ],
    },
    {
      value: `lvl_6`,
      label: `${t("lvl")}.6`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `200$` },
        { money: `100`, procent: `200$` },
        { money: `200`, procent: `200$` },
        { money: `300`, procent: `300$` },
        { money: `500`, procent: `340$` },
        { money: `1000`, procent: `360$` },
        { money: `2000`, procent: `400$` },
        { money: `5000`, procent: `400$` },
        { money: `10000`, procent: `400$` },
      ],
    },
    {
      value: `lvl_7`,
      label: `${t("lvl")}.7`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `400$` },
        { money: `100`, procent: `400$` },
        { money: `200`, procent: `400$` },
        { money: `300`, procent: `400$` },
        { money: `500`, procent: `600$` },
        { money: `1000`, procent: `600$` },
        { money: `2000`, procent: `800$` },
        { money: `5000`, procent: `800$` },
        { money: `10000`, procent: `800$` },
      ],
    },
    {
      value: `lvl_8`,
      label: `${t("lvl")}.8`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `1000$` },
        { money: `100`, procent: `1000$` },
        { money: `200`, procent: `1000$` },
        { money: `300`, procent: `1000$` },
        { money: `500`, procent: `1500$` },
        { money: `1000`, procent: `1500$` },
        { money: `2000`, procent: `2000$` },
        { money: `5000`, procent: `2000$` },
        { money: `10000`, procent: `2000$` },
      ],
    },
    {
      value: `lvl_9`,
      label: `${t("lvl")}.9`,
      isActive: false,
      licenseData: [
        { money: `50`, procent: `2000$` },
        { money: `100`, procent: `2000$` },
        { money: `200`, procent: `2000$` },
        { money: `300`, procent: `2000$` },
        { money: `500`, procent: `3000$` },
        { money: `1000`, procent: `3000$` },
        { money: `2000`, procent: `4000$` },
        { money: `5000`, procent: `4000$` },
        { money: `10000`, procent: `4000$` },
      ],
    },
  ];

  const REF_CARDS = [
    {
      users: 12,
      money: 0,
      color: `darck-green`,
      description: t(`refCards.quick_start`),
      partners: t(`refCards.invitePartners`),
      detailed:
        t(`refCards.quickDetailed1`) +
        t(`refCards.quickDetailed2`) +
        t("refCards.quickDetailed3"),
      tableData: QUICK_START_TABLE,
    },
    {
      users: 3740,
      money: 0,
      color: "purple",
      description: t("refCards.referalBonus"),
      partners: t("refCards.refPartners"),
      detailed: t("refCards.refDetailed"),
      tableData: REFERAL_BONUCE_TABLE,
    },
    {
      users: 8,
      money: 0,
      color: "red",
      description: t("refCards.matchingBonus"),
      partners: t("refCards.matchingPartners"),
      detailed: t("refCards.matchingDetailed"),
      tableData: MATCHING_BONUCE_TABLE,
    },
    {
      users: 14200,
      money: 0,
      color: "gold",
      description: t("refCards.promo"),
      partners: t("refCards.promoPartners"),
      detailed: t("refCards.promoDetailed"),
    },
  ];
  return (
    <div className="flex flex-col gap-6 items-center mb-[75px] pb-[100px]">
      {REF_CARDS.map((item, index) => {
        return (
          <div key={index}>
            <RefCard
              users={`${refersData?.count || 0}`}
              money={
                index === 0
                  ? formatNumber(+userData.bonus_balance)
                  : formatNumber(item.money)
              }
              color={item.color}
              description={item.description}
              partners={item?.partners}
              onClick={() => {
                setDetailedState((prev) => ({
                  ...prev,
                  color: item.color,
                  goBack: () => setRefScreen("ref"),
                  title: item.description,
                  users: `${refersData?.count || 0}`,
                  money: formatNumber(item.money),
                  description: item.detailed,
                  bonuseTableData: item.tableData,
                }));
                setRefScreen("detailedRef");
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RefCardsList;
