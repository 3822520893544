import React, { useEffect, useState } from "react";

import "./App.css";
import WelcomeScreen from "./screens/WelcomeScreen/WelcomeScreen";
import RegistrationScreen from "./screens/RegistrationScreen/RegistrationScreen";
import PinCodeScreen from "./screens/PinCodeScreen/PinCodeScreen";

import Authorized from "./screens/Authorized/Autorized";
import { api } from "./api/api";
import LoadingScreen from "./screens/Loading/LoadingScreen";
import { retrieveLaunchParams } from "@telegram-apps/sdk-react";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_LANGUAGE,
  LANGUAGE_STORAGE_KEY,
  LanguageKeys,
  languages,
} from "./constants";
import { setCookie } from "./helpers/cockieHelper";
import { postEvent } from "@telegram-apps/sdk";
import { init } from "@telegram-apps/sdk";

init();

export interface UserData {
  id: string;
  name: string;
}

function App() {
  const [screen, setScreen] = useState("loading");
  const [startParam, setStartParam] = useState("");
  const { i18n } = useTranslation();

  useEffect(() => {
    postEvent("web_app_request_fullscreen");
    postEvent("web_app_expand");
  }, []);

  useEffect(() => {
    const choosedLanguage = localStorage.getItem(LANGUAGE_STORAGE_KEY) as
      | LanguageKeys
      | undefined;
    if (choosedLanguage && languages.includes(choosedLanguage)) {
      i18n.changeLanguage(choosedLanguage);
    } else {
      i18n.changeLanguage(DEFAULT_LANGUAGE);
    }
  }, []);

  const { initDataRaw, initData } = retrieveLaunchParams();

  const telegramAuth = async () => {
    if (!initDataRaw) return;
    try {
      const body = initData?.startParam
        ? {
            init_data: initDataRaw,
            invite_code: initData?.startParam,
          }
        : {
            init_data: initDataRaw,
          };

      const response = await api.post("accounts/telegram_auth/", body, {});
      const isUserPassword = response.data.password;
      setCookie("temp_token", response.data.temp_token || "no_token", 7);

      if (isUserPassword) {
        setScreen("login");
      } else {
        setScreen("pin-code");
      }
      return response.data;
    } catch (error) {
      return false;
    }
  };

  const [userData, setUserData] = useState<UserData>({
    id: initData?.user?.id.toString() || "",
    name: initData?.user?.firstName || "",
  });

  useEffect(() => {
    telegramAuth();
  }, []);

  const appRender = () => {
    switch (screen) {
      case "welcome":
        return <WelcomeScreen setScreen={setScreen} />;
      case "registration":
        return (
          <RegistrationScreen
            setScreen={setScreen}
            userData={userData}
            startParam={startParam}
          />
        );
      case "login":
        return (
          <LoginScreen
            setScreen={() => setScreen("authorized")}
            userData={userData}
          />
        );
      case "pin-code":
        return (
          <PinCodeScreen
            setScreen={() => setScreen("login")}
            userId={userData.id}
          />
        );
      case "authorized":
        return <Authorized />;
      case "loading":
        return <LoadingScreen />;
      default:
        return <LoadingScreen />;
    }
  };
  return appRender();
}

export default App;
