import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SDKProvider } from "@telegram-apps/sdk-react";
import "./i18n";
function ensureDocumentIsScrollable() {
  const isScrollable =
    document.documentElement.scrollHeight > window.innerHeight;

  if (!isScrollable) {
    document.documentElement.style.setProperty(
      "height",
      "calc(100vh + 1px)",
      "important"
    );
  }
}

window.addEventListener("load", ensureDocumentIsScrollable);

function preventCollapse() {
  if (window.scrollY === 0) {
    window.scrollTo(0, 1);
  }
}

document.addEventListener("touchstart", preventCollapse);
if (window.visualViewport) {
  window.visualViewport.addEventListener("resize", () => {
    document.body.style.height = window.visualViewport?.height + "px";
  });
}
// This will ensure user never overscroll the page
window.addEventListener("scroll", () => {
  if (window.scrollY > 0) window.scrollTo(0, 0);
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <SDKProvider acceptCustomStyles debug={true}>
      <App />
    </SDKProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
