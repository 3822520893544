import { FC, useCallback, useEffect, useState } from "react";
import { IUserData } from "../interfaces";
import css from "./index.module.css";
import cn from "classnames";
import { ReactComponent as SettingIcon } from "../../images/icons/settings-icon.svg";
import { ReactComponent as SupportIcon } from "../../images/icons/support-icon.svg";
import { ReactComponent as FileIcon } from "../../images/icons/file-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import icon from "../../images/icons/default-icon.png";
import documentIcon from "../../images/icons/Document.svg";
import BalanceCard from "../../components/BalanceCard";
import MoneyStatistic from "../../components/MoneyStatistic/inddex";
import CopyButton from "../../components/UI/Buttons/CopyButton/CopyButton";
import Button from "../../components/UI/Buttons/Button";
import ProfileButton from "../../components/UI/Buttons/ProfileButton/ProfileButton";
import HoverModal from "../../components/HoverModal";
import { RefersData } from "../Referal";
import { api } from "../../api/api";
import { getCookie } from "../../helpers/cockieHelper";
import { formatDate, getWeeksPassed } from "../../utils/dateFormater";
import { HoverModalTypes } from "../../components/HoverModal/interfaces";
import BackButton from "../../components/UI/Buttons/BackButton/BackButton";
import { HistoryDtata } from "../../api/interfaces/history";
import UserRefCard from "../../components/UserReFCard";
import { formatNumber } from "../../utils/numberFormater";
import Modal from "../../components/UI/Modals/BuyPremiumModal/Modal";
import DocsButton from "../../components/UI/Buttons/DocsButton/DocsButton";
import CheckPinCodeScreen from "../CheckPinCodeScreen/CheckPinCodeScreen";
import { useTranslation, UseTranslationResponse } from "react-i18next";
import { mergeBundles } from "../../helpers/mergeBundle";

import { Viewer, CharacterMap } from "@react-pdf-viewer/core";
import { Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

interface MainScreenProps {
  userData: IUserData;
  setAuthorizedScreen: React.Dispatch<React.SetStateAction<string>>;
}

interface StatisticCard {
  depositAmount: number;
  procent: string;
  cardIncome: number;
  date: string;
}

const swiperSettings = {
  spaceBetween: 10,
  slidesPerView: 1,
  pagination: { clickable: true },
  loop: true,
  autoplay: { delay: 1000 },
};

type PageScreen =
  | "main"
  | "finance"
  | "insruction"
  | "wp"
  | "investors"
  | "partners";

const MainScreen: FC<MainScreenProps> = ({ userData, setAuthorizedScreen }) => {
  const { t } = useTranslation();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<HoverModalTypes>("withdrawal");
  const [refersCount, setRefersCount] = useState<number>(0);
  const [statisticCard, setStatisticCards] = useState<
    StatisticCard[] | undefined
  >(undefined);
  const userRefLink = `${
    process.env.REACT_APP_BOT_LINK
      ? process.env.REACT_APP_BOT_LINK
      : "http://t.me/Memedao_bot"
  }/app?startapp=${userData.refery_code}`;
  const [pageScreen, setPageScreen] = useState<PageScreen>("main");
  const [history, setHistory] = useState<HistoryDtata>({} as HistoryDtata);
  const [avatar, setAvatar] = useState<string>(icon);
  const [name, setName] = useState<string>(userData.name);
  const [isPinCodeOpen, setPinCodeOpen] = useState<boolean>(false);

  useEffect(() => {
    if (userData.profile_pic) {
      setAvatar(userData.profile_pic);
    }
  }, []);

  const getUserData = async () => {
    try {
      const response = await api.get(`/profile/me/`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getCookie("key")?.toString()} `, // Токен из куки
          "Content-Type": "application/json",
        },
      });

      setAvatar(response.data.profile_pic);
      setName(response.data.name);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const getHistory = async () => {
    try {
      const response = await api.get<HistoryDtata>(
        `/profile/me/history/?type=REFERRAL_FEES&page=1&page_size=500`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getCookie("key")?.toString()} `,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) setHistory(response.data);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    getHistory();
  }, [pageScreen]);

  useEffect(() => {
    if (!userData?.invest_bundles) return;

    const gropedUserBundles = mergeBundles(userData?.invest_bundles);

    const newCards = gropedUserBundles.map((bundle) => {
      const level = Math.floor(bundle.max_slots / 5) || 1;
      const slotCoast = +bundle.bundle_price / 5;
      const slots = bundle.slots;
      const price = slotCoast * slots + level * slotCoast;
      const procent = bundle.profit_percent;
      const createdDate = formatDate(bundle.created_at);
      const cardInc =
        getWeeksPassed(bundle.created_at) * (+procent / 100) * price;

      return {
        depositAmount: price,
        procent: procent,
        date: createdDate,
        cardIncome: cardInc,
      };
    });

    setStatisticCards(newCards);

    if (newCards) {
      if (newCards.length !== 0) {
        const comentStatistic = newCards.reduce(
          (acc, item) => {
            acc.cardIncome += item.cardIncome;
            acc.depositAmount += item.depositAmount;
            if (parseFloat(item.procent) > parseFloat(acc.procent)) {
              acc.procent = item.procent;
            }
            if (
              new Date(item.date.split(".").reverse().join("-")) <
              new Date(acc.date.split(".").reverse().join("-"))
            ) {
              acc.date = item.date;
            }
            return acc;
          },
          {
            cardIncome: 0,
            depositAmount: 0,
            procent: "0",
            date: newCards[0]?.date,
          }
        );

        setStatisticCards([comentStatistic, ...newCards]);
      }
    }
  }, [userData]);

  useEffect(() => {
    const getRefData = async () => {
      try {
        const response = await api.get<RefersData>(`/profile/me/referrals/`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getCookie("key")?.toString()} `,
            "Content-Type": "application/json",
          },
        });

        if (response.data.results.length)
          setRefersCount(response?.data?.results?.length);
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    };
    getRefData();
  }, []);

  const handleOpenPinCodeScreen = useCallback(() => {
    setPinCodeOpen(true);
  }, []);

  const handlePinCodeSuccess = useCallback(() => {
    setPinCodeOpen(false);
    setModalType("withdrawal");
    setOpenModal(true);
  }, []);

  const mainPageRender = () => {
    switch (pageScreen) {
      case "main":
        return (
          <div className={css.wrapper}>
            <div className={css.header}>
              <div className={css.left}>
                <img
                  src={avatar || icon}
                  alt="Profile Avatar"
                  className={css.avatar}
                />
                <div>
                  <p className={css.username}>{name}</p>
                  <p className={css.userId}>#{userData.id}</p>
                </div>
              </div>
              <div className={css.setingsWrapper}>
                <div
                  onClick={() => {
                    setAuthorizedScreen("settings");
                  }}
                >
                  <SettingIcon />
                </div>
              </div>
            </div>
            <div className={css.UserCards}>
              <BalanceCard
                withdrawMoney={handleOpenPinCodeScreen}
                balance={userData.balance}
              />
            </div>

            {!!statisticCard?.length && (
              <Swiper {...swiperSettings} className="max-w-full">
                {statisticCard?.map((el, index) => (
                  <SwiperSlide key={index}>
                    <div className="w-full mx-auto">
                      <MoneyStatistic
                        depositAmount={el.depositAmount}
                        procent={el.procent}
                        cardIncome={el.cardIncome}
                        date={el.date}
                        userRefLink={userRefLink}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}

            {!!statisticCard?.length && (
              <>
                <div className={css.refWrapper}>
                  <CopyButton
                    value={userRefLink}
                    type="detailed"
                    refersCount={refersCount}
                    bonuces={userData.bonus_balance}
                  />
                </div>
                <div className={css.docsButtonWrapper}>
                  <DocsButton
                    openFile={() => setPageScreen("insruction")}
                    text={t("links.INSTRUCTION")}
                    imageSrc={documentIcon}
                    link={t("links.insruction_file")}
                  />
                  <DocsButton
                    openFile={() => setPageScreen("wp")}
                    text="WP MEME-DAO"
                    imageSrc={documentIcon}
                    link={t("links.wp_file")}
                  />
                  <DocsButton
                    openFile={() => setPageScreen("investors")}
                    text={t("links.FOR_INVESTORS")}
                    imageSrc={documentIcon}
                    link={t("links.investors_file")}
                  />
                  <DocsButton
                    openFile={() => setPageScreen("partners")}
                    text={t("links.FOR_PARTNERS")}
                    imageSrc={documentIcon}
                    link={t("links.partners_file")}
                  />
                </div>
                <div className={css.financeButtonWrapp}>
                  <Button
                    onClick={() => {
                      setPageScreen("finance");
                    }}
                  >
                    <FileIcon />
                    {t("finance")}
                  </Button>
                </div>

                <ProfileButton
                  Icon={SupportIcon}
                  value={t("support")}
                  onClick={() => {
                    setOpenModal(true);
                    setModalType("support");
                  }}
                />
              </>
            )}
            {statisticCard && !statisticCard?.length && (
              <>
                <div className={css.docsButtonWrapper}>
                  <DocsButton
                    openFile={() => setPageScreen("insruction")}
                    text={t("links.INSTRUCTION")}
                    imageSrc={documentIcon}
                    link={t("links.insruction_file")}
                  />
                  <DocsButton
                    openFile={() => setPageScreen("wp")}
                    text="WP MEME-DAO"
                    imageSrc={documentIcon}
                    link={t("links.wp_file")}
                  />
                  <DocsButton
                    openFile={() => setPageScreen("investors")}
                    text={t("links.FOR_INVESTORS")}
                    imageSrc={documentIcon}
                    link={t("links.investors_file")}
                  />
                  <DocsButton
                    openFile={() => setPageScreen("partners")}
                    text={t("links.FOR_PARTNERS")}
                    imageSrc={documentIcon}
                    link={t("links.partners_file")}
                  />
                </div>
                <Modal
                  type="premium"
                  onClick={() => setAuthorizedScreen("memetrade")}
                  position="statick"
                />
              </>
            )}
            {isOpenModal && (
              <HoverModal
                close={() => setOpenModal(false)}
                type={modalType}
                setModalType={setModalType}
              />
            )}
          </div>
        );
      case "finance":
        return (
          <div className={css.financeWrapper}>
            <BackButton goBack={() => setPageScreen("main")} color={"white"} />
            <h1 className={css.financeTitle}>{t("finance")}</h1>

            <div className={css.historyWrap}>
              {history?.results?.map((item, index) => (
                <div key={index}>
                  <UserRefCard
                    mail={item.detail.email}
                    usdt={`${formatNumber(+item.change)} USDT`}
                    level={formatDate(item.created_at)}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      case "insruction":
        return (
          <div className={css.wrapper}>
            <BackButton goBack={() => setPageScreen("main")} color={"white"} />

            <div className={css.workerWrapper}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={t("links.insruction_file")}
                  defaultScale={0.2}
                />
              </Worker>
            </div>
          </div>
        );
      case "wp":
        return (
          <div className={css.wrapper}>
            <BackButton goBack={() => setPageScreen("main")} color={"white"} />
            <div className={css.workerWrapper}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={t("links.wp_file")} defaultScale={0.6} />
              </Worker>
            </div>
          </div>
        );
      case "investors":
        return (
          <div className={css.wrapper}>
            <BackButton goBack={() => setPageScreen("main")} color={"white"} />
            <div className={css.workerWrapper}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={t("links.investors_file")}
                  defaultScale={0.2}
                />
              </Worker>
            </div>
          </div>
        );
      case "partners":
        return (
          <div className={css.wrapper}>
            <BackButton goBack={() => setPageScreen("main")} color={"white"} />
            <div className={css.workerWrapper}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={t("links.partners_file")} defaultScale={0.2} />
              </Worker>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      {isPinCodeOpen ? (
        <CheckPinCodeScreen onSuccess={handlePinCodeSuccess} />
      ) : (
        mainPageRender()
      )}
    </>
  );
};

export default MainScreen;
