import { FC } from "react";
import css from "./index.module.css";
import cn from "classnames";
import Mascot from "../../images/svg/meme.svg";
import { formatNumber } from "../../utils/numberFormater";
import ShareButton from "../UI/Buttons/ShareButton";
import { useTranslation } from "react-i18next";

interface MoneyStatisticProps {
  depositAmount: number;
  procent: string;
  cardIncome: number;
  date: string;
  userRefLink: string;
}
const MoneyStatistic: FC<MoneyStatisticProps> = ({
  depositAmount,
  procent,
  cardIncome,
  date,
  userRefLink,
}) => {
  const { t } = useTranslation();
  const inviteText = `❇️ ${t("moneySstatistic.sharing_my_successes")} ❇️
    
    ${t("moneySstatistic.join_MEME_with_a_referral_link")}👾
    
     ${t("date")}: ${date}
    ${t("deposit")}: $${depositAmount ? depositAmount.toFixed(2) : 0}
    ${t("eraned")}:: $${cardIncome.toFixed(2)}
   ${t("profitability")}:: ${procent}%
              `;
  return (
    <div className={css.wrap}>
      <div className={css.main}>
        <div className={css.moneyList}>
          <div>
            <p className={css.description}>
              {t("moneySstatistic.total_deposit_mount").toLocaleUpperCase()}:
            </p>
            <p className={css.amount}>${formatNumber(depositAmount)}</p>
          </div>
          <div>
            <p className={css.description}>
              {t("eraned").toLocaleUpperCase()}:
            </p>
            <p className={cn(css.amount, css.amountGreen)}>
              ${formatNumber(cardIncome)}
            </p>
          </div>
          <div>
            <p className={css.description}>
              {t("profitability").toLocaleUpperCase()}:
            </p>
            <p className={css.amount}>{procent}%</p>
          </div>
        </div>

        <ShareButton userRefLink={userRefLink} inviteText={inviteText} />
      </div>

      <div className={css.footer}>
        <p>
          {t("date")}: {date}
        </p>
      </div>
      <div>
        <img src={Mascot} alt="Персонаж" className={css.memeImg} />
      </div>
    </div>
  );
};

export default MoneyStatistic;
