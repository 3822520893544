"use client";

import Welcome from "../../images/svg/welcome.svg";
import WelcomeBg from "../../images/welcomeBg.png";

const LoadingScreen = () => {
  return (
    <div className="relative w-full h-screen flex flex-col justify-center items-center">
      {/* Фоновое изображение на весь экран */}
      <div className="absolute inset-0">
        <img src={WelcomeBg} alt="Фон приветствия" />
      </div>

      {/* Контент поверх фона */}
      <div className="relative z-10 w-full h-full flex flex-col  items-center">
        <div className="w-full flex justify-center">
          <img src={Welcome} alt="Картинка приветствия" className="w-full" />
        </div>
        <div className="flex flex-col gap-[32px] uppercase px-6 pt-11 pb-6 absolute bg-black/40 bottom-0">
          <span className="text-[100px] font-semibold">Loading ...</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
