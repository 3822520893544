export const formatNumber = (number: number): string => {
  return number
    .toFixed(2)
    .replace(/\.00$/, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const formatMoney = (number: number): string => {
  console.log("nb", number);
  switch (true) {
    case number < 1000:
      return number.toString();

    case number >= 1000 && number < 1000000:
      return Math.round(number / 1000) + "K";

    case number >= 1000000:
      return (number / 1000000).toFixed(2) + "M";

    default:
      return number.toString();
  }
};
