import { FC, useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Memtrade from "../Memtrade/Memtrade";
import Profile from "../Profile/Profile";
import { api } from "../../api/api";
import { getCookie } from "../../helpers/cockieHelper";
import { IUserData } from "../interfaces";
import Referal from "../Referal";
import MainScreen from "../Main";
import { useTranslation } from "react-i18next";

const Authorized: FC = () => {
  const { t } = useTranslation();
  const [authorizedScreen, setAuthorizedScreen] = useState("home");
  const [isFooterVisible, setFooterVisible] = useState<boolean>(true);
  const [userData, setUserData] = useState<IUserData>({} as IUserData);

  const getUserData = async () => {
    try {
      const response = await api.get(`/profile/me/`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getCookie("key")?.toString()} `,
          "Content-Type": "application/json",
        },
      });
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const authorizedRender = () => {
    switch (authorizedScreen) {
      case "memetrade":
        return <Memtrade userData={userData} getUserData={getUserData} />;
      case "home":
        return (
          <MainScreen
            setAuthorizedScreen={setAuthorizedScreen}
            userData={userData}
          />
        );
      case "game":
        return <h1>123</h1>;
      case "referal":
        return (
          <Referal
            userData={userData}
            setAuthorizedScreen={setAuthorizedScreen}
          />
        );
      case "settings":
        return <Profile setFooterVisible={setFooterVisible} />;
    }
  };
  return (
    <div>
      {authorizedRender()}
      {isFooterVisible && (
        <Footer
          setAuthorizedScreen={setAuthorizedScreen}
          activeItem={authorizedScreen}
        />
      )}
    </div>
  );
};

export default Authorized;
