import { FC, useEffect, useRef, useState } from "react";

import css from "./modal.module.css";
import Button from "../../Buttons/Button";
import cn from "classnames";
import LoaderIcon from "../../../../images/icons/loader-icon.component";
import SuccesIcon from "../../../../images/icons/succes-icon.component";
import FalledIcon from "../../../../images/icons/falled-icon.component";
import { ModalProps } from "../../../../screens/interfaces";
import ModalTableHeaders from "../../../ModalTableHeaders";
import { BonucesTableType } from "../../../RefCardsList/constants";
import { useTranslation } from "react-i18next";
import { formatMoney } from "../../../../utils/numberFormater";

const Modal: FC<ModalProps> = ({
  onClick,
  type,
  amount,
  color,
  position,
  bonuseTableData,
  onClose,
}) => {
  const { t } = useTranslation();
  const [headers, setHeaders] = useState<BonucesTableType[]>(
    bonuseTableData as BonucesTableType[]
  );
  const [activeTab, setActiveTab] = useState<number>(0);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        onClick
      ) {
        onClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClick]);

  const handleClicHeaders = (index: number) => {
    const updatedHeaders = headers.map((item, i) => {
      const isActive = i === index;
      if (isActive) setActiveTab(index);
      return {
        ...item,
        isActive,
      };
    });

    setHeaders(updatedHeaders);
  };
  console.log(amount);
  const bodyRender = () => {
    switch (type) {
      case "premium":
        return (
          <>
            <h1 className={css.title}>
              {t("modal.buyDao")}
              {/* MEME-DAO{t("modal.meme_descrp")}
              <br />
              -ENTER-DAO{t("modal.enter_desc")}
              <br />
              -MY GUYS{t("modal.my_guys_descp1")}
              <br />
              {t("modal.my_guys_descp2")}
              <br /> -POSITIVE BALANCE - {t("modal.positive_balance")}
              <br /> {t("modal.choise_enter")} */}
            </h1>

            <Button onClick={onClick}>
              {t("Buy")} ENTER-DAO
              {amount
                ? ` ${formatMoney(+amount.slice(0, amount.length - 1))}`
                : ""}
            </Button>
          </>
        );
      case "pending":
        return (
          <>
            <div className={css.iconWrap}>
              <div className={css.loaderWrap}>
                <LoaderIcon />
              </div>
            </div>
            <div className={css.titleWrap}>
              <span className={css.title}>
                {" "}
                {t("modal.We_are_waiting_for_payment")}
              </span>
            </div>
          </>
        );
      case "success":
        return (
          <>
            <div className={css.iconWrap}>
              <SuccesIcon />
            </div>
            <div className={css.titleWrap}>
              <p className={css.title}>{t("modal.Successful_payment")}</p>
            </div>
            <Button onClick={onClick}>Ок</Button>
          </>
        );
      case "falled":
        return (
          <>
            <div className={css.iconWrap}>
              <FalledIcon />
            </div>
            <div className={css.titleWrap}>
              <span className={css.title}>{t("modal.Payment_failed")}</span>
            </div>
            <Button onClick={onClick} className={"bg-white"}>
              {t("modal.Repeat_payment")}
            </Button>
          </>
        );
      case "table":
        return (
          <div ref={modalRef}>
            <h1 className={css.tableHeader}>
              {" "}
              {t("refCard.profitability_table")}
            </h1>
            <ModalTableHeaders
              handleClicHeaders={handleClicHeaders}
              headers={headers}
              onClose={onClick}
            />
            <div className={css.RefListWrapper}>
              {headers[activeTab] &&
                headers[activeTab].licenseData.map((item) => {
                  return (
                    <>
                      <div className={css.listElement}>
                        <div>
                          <p className={css.listTitle}>
                            {t("modal.PERSONAL_LICENSE")}
                          </p>
                          <p className={css.listAmount}>${item.money}</p>
                        </div>
                        <div>
                          <p className={cn(css.listTitle, css.right)}>
                            {t("modal.bonus")}
                          </p>
                          <p
                            className={cn(css.listAmount, {
                              [css.center]: item.procent === "x",
                            })}
                          >
                            {item.procent[0] === "x" ? "-" : item.procent}
                          </p>
                        </div>
                      </div>
                      <div></div>
                    </>
                  );
                })}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const modalWrapClass = cn(css.wrapper, {
    [css.blue]: type === "pending",
    [css.red]: type === "falled",
    [css.purpleBig]: type === "table" && color === "purple",
    [css.darckGreenBig]: type === "table" && color === "darck-green",
    [css.redBig]: type === "table" && color === "red",
    [css.goldBig]: type === "table" && color === "gold",
    [css.statick]: position === "statick",
    [css.relative]: position === "relative",
  });
  return (
    <div className={modalWrapClass}>
      <div className={css.hole}></div>
      {onClose && (
        <p className={css.close} onClick={onClose}>
          X
        </p>
      )}
      {bodyRender()}
    </div>
  );
};

export default Modal;
