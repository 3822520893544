import { FC } from "react";
import css from "./DocsButton.module.css";

interface DocsButtonProps {
  imageSrc: string;
  text: string;
  link: string;
  openFile: () => void;
}

const DocsButton: FC<DocsButtonProps> = ({ imageSrc, text, openFile }) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    openFile();
  };

  return (
    <a
      download={text}
      target="_blank"
      rel="noreferrer noopener"
      className={css.wrapper}
      onClick={handleClick}
    >
      <p className={css.imageContainer}>
        <img src={imageSrc} alt="button-icon" className={css.image} />
      </p>
      <p className={css.text}>{text}</p>
    </a>
  );
};

export default DocsButton;
