import { FC } from "react";
import css from "./index.module.css";
import { ReactComponent as WithdrawIcon } from "./img/withdraw.svg";
import ButtonWithIcon from "../UI/Buttons/ButtonWithIcon/ButtonWithIcon";
import { formatNumber } from "../../utils/numberFormater";
import { useTranslation } from "react-i18next";

interface BalanceCardProps {
  withdrawMoney: () => void;
  balance: string;
}

const BalanceCard: FC<BalanceCardProps> = ({ withdrawMoney, balance }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={css.balanceWrapper}>
        <div>
          <p className={css.value}>${formatNumber(+balance)}</p>
          <p className={css.description}>{t("balance")}</p>
        </div>
        <div className={css.buttonWrapper} onClick={withdrawMoney}>
          <ButtonWithIcon Icon={WithdrawIcon} value={t("withdraw")} />
        </div>
      </div>
    </>
  );
};

export default BalanceCard;
