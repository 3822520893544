import { FC, useEffect, useState } from "react";
import css from "./index.module.css";
import cn from "classnames";
import CopyButton from "../Buttons/CopyButton/CopyButton";
import { ReactComponent as WalletIcon } from "../../../images/icons/wallet.svg";
import { ReactComponent as ProfileIcon } from "../../../images/icons/user.svg";
import { formatNumber } from "../../../utils/numberFormater";
import { IUserData } from "../../../screens/interfaces";
import { api } from "../../../api/api";
import { getCookie } from "../../../helpers/cockieHelper";
import FileIcon from "../../../images/icons/file-icon-ref.svg";
import { useTranslation, UseTranslationResponse } from "react-i18next";


interface RefCardBigProps {
  color: string;
  users: string;
  money: string;
  title: string;
  description: string;
  userData: IUserData;
  setTableModal: React.Dispatch<React.SetStateAction<boolean>>;
  showTable: boolean;
}

interface ReferalProggramInfo {
  referrals_first_lvl: number;
  total_referrals: number;
  total_sales: number;
  total_income: string;
}
const RefCardBig: FC<RefCardBigProps> = ({
  color,
  users,
  money,
  description,
  title,
  userData,
  setTableModal,
  showTable,
}) => {
  const [referalProggramInfo, setReferalProggramInfo] =
    useState<ReferalProggramInfo>({} as ReferalProggramInfo);

  useEffect(() => {
    const getReferalInfo = async () => {
      try {
        const response = await api.get<ReferalProggramInfo>(
          `/profile/me/referral_fees_program/`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${getCookie("key")?.toString()} `,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) setReferalProggramInfo(response.data);
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    };
    getReferalInfo();
  }, []);

  const { t } = useTranslation();
  return (
    <div
      className={cn(css.wrapper, {
        [css.backgroundGoldBig]: color === "gold",
        [css.backgroundDarckGreendBig]: color === "darck-green",
        [css.backgroundLigthGreenBig]: color === "ligth-green",
        [css.backgroundLigthOrangeBig]: color === "orange",
        [css.backgroundLigthPinkBig]: color === "pink",
        [css.backgroundBlueBig]: color === "blue",
        [css.backgroundRedBig]: color === "red",
        [css.backgroundPurpleBig]: color === "purple",
        [css.backgroundGreenBig]: color === "green",
      })}
    >
      <p className={css.title}>{title}</p>
      <p className={css.description}>{description}</p>

      {showTable && (
        <div className={css.eranTableRef} onClick={() => setTableModal(true)}>
          <img src={FileIcon} alt="file" />
          <span className={css.earnTableDescription}>
            {t("refCard.profitability_table")}
          </span>
        </div>
      )}

      <div className={css.customLine}></div>
      <div className={css.footer}>
        <div className={css.footerRow}>
          <div className={css.footerElement}>
            <p className={css.footerTitle}>
              {" "}
              {t("refCard.LEVEL_1_REFERRALS")}:
            </p>
            <p className={css.footerAmount}>
              {referalProggramInfo.referrals_first_lvl}
            </p>
          </div>
          <div className={css.footerElement}>
            <p className={css.footerTitle}>
              {t("refCard.SALES_IN_STRUCTURE")}:
            </p>
            <p className={cn(css.footerAmount, css.right)}>
              {referalProggramInfo.total_sales}
            </p>
          </div>
        </div>
        <div className={css.footerRow}>
          <div className={css.footerElement}>
            <p className={css.footerTitle}>{t("refCard.TOTAL_REFERRALS")}:</p>
            <p className={css.footerAmount}>
              {referalProggramInfo.total_referrals}
            </p>
          </div>
          <div className={css.footerElement}>
            <p className={css.footerTitle}>{t("refCard.TOTAL_EARNED")}:</p>
            <p className={cn(css.footerAmount, css.right)}>
              ${formatNumber(+referalProggramInfo.total_income)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefCardBig;
