import { FC, useCallback, useEffect, useState } from "react";
import { getCookie } from "../../helpers/cockieHelper";
import KeyboardList from "../../components/KeyboardList";
import { useTranslation } from "react-i18next";

interface CheckPinCodeScreenProps {
  onSuccess: () => void;
  onFailure?: () => void;
}

const CheckPinCodeScreen: FC<CheckPinCodeScreenProps> = ({
  onSuccess,
  onFailure,
}) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>("****");
  const [title, setTitle] = useState<string>(t("pin.Enter_PIN_code") as string);
  const savedPin = getCookie("password");

  const checkPinCode = useCallback(() => {
    if (code === savedPin) {
      setTitle(t("pin.The_pin_code_is_correct") as string);
      setTimeout(() => {
        onSuccess();
      }, 1000);
    } else {
      setTitle(t("pin.Incorrect_PIN_code") as string);
      if (onFailure) onFailure();
      setTimeout(() => {
        setCode("****");
        setTitle(t("pin.Enter_PIN_code") as string);
      }, 1000);
    }
  }, [code, savedPin, onSuccess, onFailure]);

  useEffect(() => {
    if (code.indexOf("*") === -1) {
      checkPinCode();
    }
  }, [code, checkPinCode]);

  const handleDelete = useCallback(() => {
    setCode((prev) => {
      if (prev === "****") return prev;

      const lastDigitIndex = prev.search(/\d(?=\*|$)/);

      if (lastDigitIndex === -1) {
        return prev.slice(0, prev.length - 1) + "*";
      }

      return (
        prev.slice(0, lastDigitIndex) + "*" + prev.slice(lastDigitIndex + 1)
      );
    });
  }, []);

  const handleAddNumber = useCallback((number: string) => {
    setCode((prev) => {
      const index = prev.indexOf("*");
      if (index !== -1) {
        return prev.substring(0, index) + number + prev.substring(index + 1);
      }
      return prev;
    });
  }, []);

  return (
    <div className="flex flex-col items-center h-screen w-screen">
      <section className="flex flex-col items-center w-full bg-gradient-to-b from-[#9EE449] to-[#232323] pt-[70px]">
        <span className="text-[56px]  ml-5">{title}</span>
        <span className="text-[150px]">{code}</span>
      </section>
      <KeyboardList onDelete={handleDelete} onAdd={handleAddNumber} />
    </div>
  );
};

export default CheckPinCodeScreen;
