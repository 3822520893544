import { api } from "../../api/api";
import KeyboardList from "../../components/KeyboardList";

import { FC, useCallback, useEffect, useState } from "react";
import { getCookie } from "../../helpers/cockieHelper";
import { setCookie } from "../../helpers/cockieHelper";
import { UserData } from "../../App";
import { useTranslation } from "react-i18next";

interface LoginScreenProps {
  setScreen: () => void;
  userData: UserData;
}

const LoginScreen: FC<LoginScreenProps> = ({ setScreen, userData }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>("****");
  const [title, setTitle] = useState<string>(t("pin.Enter_PIN_code") as string);

  const handleLogin = async () => {
    try {
      const body = {
        password: code + code + code,
        telegram_id: userData.id,
      };

      const response = await api.post("accounts/login/password", body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getCookie("temp_token"),
        },
      });
      setCookie("key", response.data.token, 7);
      setCookie("password", code, 7);
      setScreen();

      console.log("Password changed successfully:", response.data);
    } catch (error) {
      console.log(error);
      setTitle(t("pin.Incorrect_PIN_code") as string);
      setCode("****");
    }
  };

  useEffect(() => {
    if (code.indexOf("*") === -1) {
      setTimeout(() => {
        handleLogin();
      }, 100);
    }
  }, [code]);

  const handleDelete = useCallback(() => {
    setCode((prev) => {
      if (prev === "****") return prev;

      const lastDigitIndex = prev.search(/\d(?=\*|$)/);

      if (lastDigitIndex === -1) {
        return prev.slice(0, prev.length - 1) + "*";
      }

      return (
        prev.slice(0, lastDigitIndex) + "*" + prev.slice(lastDigitIndex + 1)
      );
    });
  }, [setCode]);

  const handleAddNumber = useCallback(
    (number: string) => {
      {
        setCode((prev) => {
          const index = prev.indexOf("*");
          if (index !== -1) {
            return (
              prev.substring(0, index) + number + prev.substring(index + 1)
            );
          }
          return prev;
        });
      }
    },
    [setCode]
  );

  return (
    <div className="flex flex-col items-center h-screen w-screen">
      <section className="flex flex-col items-center w-full bg-gradient-to-b from-[#9EE449] to-[#232323]  pt-[100px]">
        <span className="text-[56px] ml-5">{title}</span>
        <span className="text-[150px]">{code}</span>
      </section>
      <KeyboardList onDelete={handleDelete} onAdd={handleAddNumber} />
    </div>
  );
};

export default LoginScreen;
