import axios from "axios";

export const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL || "https://memback.taskcomplete.ru/api/v1/",
});

export const apiEcvaring = axios.create({
  baseURL:
    process.env.REACT_APP_ECVARING_API_URL ||
    "https://scam-pay-crypto.taskcomplete.ru",
  timeout: 18000,
});
