import clsx from "clsx";
import MaskedInput from "react-input-mask";
import React, { useState } from "react";

interface IClasses {
  root?: string;
  input?: string;
  label?: string;
  error?: string;
}

interface IProps {
  label?: string;
  value?: string;
  onChange?: (newValue: string) => void;
  onFocus?: (newValue?: string) => void;
  onBlur?: (newValue?: string) => void;
  type?: React.HTMLInputTypeAttribute;
  classes?: IClasses;
  error?: string;
  placehoder?: string;
  readOnly?: boolean;
  info?: React.ReactNode;
  multiline?: boolean;
  mask?: string;
  maskChar?: string;
  icon?: React.ReactNode;
  setIsKeyBoardOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const TextInput: React.FC<IProps> = ({
  onChange,
  onFocus,
  onBlur,
  value = "",
  label,
  type = "text",
  classes = {},
  error,
  placehoder,
  readOnly,
  info,
  multiline,
  mask,
  maskChar,
  icon,
  setIsKeyBoardOpen,
}) => {
  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    onChange?.(e.currentTarget.value);
  };

  const handleFocus: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    onFocus?.(e.currentTarget.value);
  };

  const handleBlur: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    onBlur?.(e.currentTarget.value);
  };

  return (
    <div
      className={clsx(
        "relative block w-full bg-[#3F3F3F] rounded-lg",
        classes.root,
        icon && "flex items-center"
      )}
    >
      {(label || info) && (
        <div className="flex justify-between mb-3">
          {label && (
            <span className={clsx("block text-[#fff] text-base")}>{label}</span>
          )}
          {info}
        </div>
      )}

      {multiline ? (
        <textarea
          value={value}
          onChange={handleChange}
          placeholder={placehoder}
          className={clsx(
            "w-full text-[#fff] bg-[#3F3F3F] text-sm border-[#BEBEBF] border min-h-[72px] rounded-lg text-blue outline-none px-6 py-4",
            classes.input
          )}
          disabled={readOnly}
          rows={4}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      ) : mask ? (
        <MaskedInput
          value={value}
          onChange={handleChange}
          type={type}
          placeholder={placehoder}
          className={clsx(
            " w-full text-[#fff] bg-[#3F3F3F] text-sm border-[#BEBEBF] border h-[72px] rounded-lg text-blue outline-none px-6",
            classes.input
          )}
          disabled={readOnly}
          onFocus={handleFocus}
          onBlur={handleBlur}
          mask={mask}
          maskChar={maskChar}
          alwaysShowMask={false}
        />
      ) : (
        <input
          value={value || ""}
          onChange={handleChange}
          type={type}
          placeholder={placehoder}
          className={clsx(
            " w-full text-[#fff] bg-[#3F3F3F] text-2xl border-[#BEBEBF] border h-[72px] rounded-lg text-blue outline-none px-6",
            classes.input
          )}
          disabled={readOnly}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}

      {icon && icon}

      {error && (
        <div className={clsx(classes.error, "text-red-500 text-xs mt-1")}>
          {error}
        </div>
      )}
    </div>
  );
};

export default TextInput;
