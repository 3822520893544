import { api } from "../../api/api";
import KeyboardList from "../../components/KeyboardList";

import { FC, useCallback, useEffect, useState } from "react";
import { getCookie, setCookie } from "../../helpers/cockieHelper";
import { useTranslation } from "react-i18next";

interface PinCodeScreenProps {
  setScreen: () => void;
  userId: string;
}

const PinCodeScreen: FC<PinCodeScreenProps> = ({ setScreen, userId }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>("****");
  const [password1, setPassword1] = useState<string>("");
  const [title, setTitle] = useState<string>(t("pin.Enter_PIN_code") as string);

  const hanleChangePassword = async () => {
    try {
      const body = {
        telegram_id: userId,
        password: code + code + code,
        confirm_password: password1 + password1 + password1,
      };
      console.log(getCookie("temp_token"));
      const response = await api.post("accounts/login/password_new", body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getCookie("temp_token"),
        },
      });
      setCookie("password", code, 7);
      setCookie("key", response.data.token, 7);
      console.log("Password changed successfully:", response.data);
    } catch (error: any) {
      console.error("Error during password change:", error);
    }
  };

  useEffect(() => {
    if (code.indexOf("*") === -1) {
      setTimeout(() => {
        if (password1.length === 0) {
          setPassword1(code);
          setTitle(t("pin.enter_again") as string);
          setCode("****");
        } else if (password1 === code) {
          hanleChangePassword();
          setScreen();
        } else {
          setTitle(t("pin.not_match") as string);
          setPassword1("");
          setCode("****");
        }
      }, 100);
    }
  }, [code]);

  const handleDelete = useCallback(() => {
    setCode((prev) => {
      if (prev === "****") return prev;

      const lastDigitIndex = prev.search(/\d(?=\*|$)/);

      if (lastDigitIndex === -1) {
        return prev.slice(0, prev.length - 1) + "*";
      }

      return (
        prev.slice(0, lastDigitIndex) + "*" + prev.slice(lastDigitIndex + 1)
      );
    });
  }, [setCode]);

  const handleAddNumber = useCallback(
    (number: string) => {
      {
        setCode((prev) => {
          const index = prev.indexOf("*");
          if (index !== -1) {
            return (
              prev.substring(0, index) + number + prev.substring(index + 1)
            );
          }
          return prev;
        });
      }
    },
    [setCode]
  );

  return (
    <div className="flex flex-col items-center h-screen w-screen">
      <section className="flex flex-col items-center w-full bg-gradient-to-b from-[#9EE449] to-[#232323]  pt-[100px]">
        <span className="text-[56px] ml-5">{title}</span>
        <span className="text-[150px]">{code}</span>
      </section>
      <KeyboardList onDelete={handleDelete} onAdd={handleAddNumber} />
    </div>
  );
};

export default PinCodeScreen;
