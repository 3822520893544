import { FC } from "react";
import ShareIcon from "../../../../images/icons/share.svg";

import { shareURL } from "@telegram-apps/sdk";

interface ShareButtonProps {
  inviteText: string;
  userRefLink: string;
}

const handleClick = (
  event: React.MouseEvent<HTMLElement>,
  inviteText: string,
  userRefLink: string
) => {
  event.stopPropagation();

  if (shareURL.isAvailable()) {
    shareURL(userRefLink, inviteText);
  } else alert("не поддерживается");
};

const ShareButton: FC<ShareButtonProps> = ({ inviteText, userRefLink }) => {
  return (
    <div
      className="cursor-pointer z-10"
      onClick={(event) => {
        handleClick(event, inviteText, userRefLink);
      }}
    >
      <img src={ShareIcon} alt="отправить" />
    </div>
  );
};
export default ShareButton;
