import { FC, useEffect, useState } from "react";
import ProfileButton from "../../components/UI/Buttons/ProfileButton/ProfileButton";
import Button from "../../components/UI/Buttons/Button";
import TextInput from "../../components/UI/Inputs/TextInput";
import { ReactComponent as ChangeIcon } from "../../images/icons/change-icon.svg";
import icon from "../../images/icons/default-icon.png";
import css from "./index.module.css";
import { getCookie } from "../../helpers/cockieHelper";
import { IUserData } from "../interfaces";
import { api } from "../../api/api";
import { useTranslation } from "react-i18next";
import { LANGUAGE_STORAGE_KEY, LanguageKeys } from "../../constants";
import HoverModal from "../../components/HoverModal";

interface SettingsScreenProps {
  setFooterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setWindow: React.Dispatch<React.SetStateAction<string>>;
}

const languageOptions: { name: string; key: LanguageKeys }[] = [
  { name: "Русский", key: "ru" },
  { name: "English", key: "en" },
  { name: "Español", key: "es" },
  { name: "Deutsch", key: "ge" },
];

const SettingsScreen: FC<SettingsScreenProps> = ({
  setFooterVisible,
  setWindow,
}) => {
  const { t, i18n } = useTranslation();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [avatar, setAvatar] = useState<string>(icon);
  const [uploadImage, setUploaadImage] = useState<File>();
  const [userData, setUserData] = useState<IUserData>({} as IUserData);
  const [placehoder, setPlaceholder] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const getUserData = async () => {
    try {
      const response = await api.get(`/profile/me/`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getCookie("key")?.toString()} `,
          "Content-Type": "application/json",
        },
      });

      setAvatar(response.data.profile_pic);
      if (response.data.name) setPlaceholder(response.data.name.toUpperCase());

      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (userData.email) setEmail(userData.email.toUpperCase());
  }, [userData]);

  useEffect(() => {
    if (userData.profile_pic) {
      setAvatar(userData.profile_pic);
    }
  }, []);

  const handleSave = async () => {
    const formData = new FormData();
    if (name.length > 0 || uploadImage) {
      if (name.length > 0) {
        formData.append("name", name);
      }
      if (uploadImage) {
        formData.append("profile_pic", uploadImage);
      }

      try {
        const response = await api.patch(`/profile/me/`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getCookie("key")?.toString()} `,
          },
        });
        setAvatar(process.env.REACT_APP_API_URL + response.data.profile_pic);
        setPlaceholder(response.data.name);
        setName("");
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    } else {
      console.log("загрузите картинку или заполните поле Ник");
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploaadImage(file);
      alert("Изображение загружено. Нажмите кнопку сохранить.");
    } else {
      alert("Попробуйте еще раз");
    }
  };

  return (
    <>
      <div className={css.settingsWrapper}>
        <p className={css.settingsTitle}>
          {t("settings.settings").toUpperCase()}
        </p>
        <div className={css.settingsIconWrap}>
          <img
            src={avatar || icon}
            alt="Profile Avatar"
            className={css.settingsAvatar}
          />
          <label className={css.changeIconWrap} htmlFor="avatarUpload">
            <ChangeIcon />
            <input
              id="avatarUpload"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </label>
        </div>
        <div className={css.buttons}>
          <div className={css.changeNamneWrapper}>
            <p className={css.label}>{t("settings.nickname")}</p>
            <TextInput
              placehoder={`#${placehoder}`}
              value={name}
              classes={{
                input: "text-[#7b7b7b]",
              }}
              onChange={setName}
            />
            <br></br>
            <p className={css.label}>{t("settings.email")}</p>
            {!userData.email && (
              <Button
                className="h-[72px] bg-none text-[#9EE449] text-[24px] border-solid border mb-[24px]"
                variant="custom"
                onClick={() => setOpenModal(true)}
                disabled={!!userData.email}
              >
                {t("verifyEmail.verifyEmail")}
              </Button>
            )}
            {!!userData.email && (
              <TextInput
                placehoder={`${email}`}
                classes={{
                  input: "text-[#7b7b7b]",
                }}
                readOnly
              />
            )}

            <br></br>
            <p className={css.label}>{t("settings.language")}</p>
            <select
              value={i18n.language}
              className=" w-full p-3 bg-[rgb(63_63_63)] bg-opacity-100 rounded-lg outline-none h-[72px] text-[#7b7b7b] text-[24px]"
              onChange={(event) => {
                i18n.changeLanguage(event.target.value);
                localStorage.setItem(LANGUAGE_STORAGE_KEY, event.target.value);
              }}
            >
              {languageOptions.map((language) => (
                <option key={language.key} value={language.key}>
                  {language.name}
                </option>
              ))}
            </select>
          </div>
          <ProfileButton
            value={t("settings.changePin")}
            className={css.profileButton}
            onClick={() => {
              setFooterVisible(false);
              setWindow("change_pin");
            }}
          />
          <Button
            className="h-[72px] capitalize"
            onClick={() => {
              handleSave();
            }}
          >
            {t("save")}
          </Button>
        </div>
      </div>
      {isOpenModal && (
        <HoverModal
          type="verifyEmail"
          close={() => {
            setOpenModal(false);
            getUserData();
          }}
        />
      )}
    </>
  );
};

export default SettingsScreen;
