export interface InvestBundle {
  id: number;
  bundle: string;
  bundle_price: string;
  profit_percent: string;
  slots: number;
  max_slots: number;
  next_coupon_date: string;
  created_at: string;
}

export const mergeBundles = (bundles: InvestBundle[]): InvestBundle[] => {
  const merged: Record<string, InvestBundle> = {};

  bundles.forEach((bundle) => {
    if (!merged[bundle.bundle]) {
      // Инициализация нового объекта для текущего bundle
      merged[bundle.bundle] = {
        ...bundle, // Скопировать текущий объект
        slots: 0,
        max_slots: 0,
      };
    }

    // Суммируем слоты
    merged[bundle.bundle].slots += bundle.slots;
    merged[bundle.bundle].max_slots += bundle.max_slots;

    // Оставляем последний id
    merged[bundle.bundle].id = bundle.id;

    // Обновляем даты
    merged[bundle.bundle].next_coupon_date =
      new Date(merged[bundle.bundle].next_coupon_date) >
      new Date(bundle.next_coupon_date)
        ? bundle.next_coupon_date
        : merged[bundle.bundle].next_coupon_date;

    merged[bundle.bundle].created_at =
      new Date(merged[bundle.bundle].created_at) > new Date(bundle.created_at)
        ? bundle.created_at
        : merged[bundle.bundle].created_at;
  });

  // Преобразование обратно в массив
  return Object.values(merged);
};
